import Cancel from 'components/elements/buttons/Cancel';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import {
    allPersons,
    convertToArray,
    getSelectedUser,
    mainPerson,
    reversePriceMask,
} from 'libs/helpers';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { AiFillCloseCircle, FaPlusCircle } from 'react-icons/all';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { useAsyncFn } from 'react-use';
import api, { ProviderType } from '@qldtuh/tuh-uh-js-api';
import gtagEvent from 'libs/gtagEvent';
import { StoreType } from 'store/types';
import { ServiceItem } from 'types/serviceItem';

interface BenefitCalculatorProps {
    handleClose: any;
    personHide: any;
    speciality: any;
}

interface BenefitItem {
    id: number;
    Fee: string;
    Quan: number;
    FeeRequired?: boolean;
    ServiceClassCode?: string;
    ServiceCode?: string;
    IsBodyPartRequired?: boolean;
}

interface OutputItem {
    PersonId: string;
    ServiceDate: string;
    ProviderCode: string;
    NumberOfServices: string | number;
    Fee: string;
    ServiceClassCode?: string;
    ServiceCode?: string;
    BodyPartCode?: string;
}

function removeBenefitItem(index: number, items: any[]) {
    const newItems = items.filter((item) => item.id !== index);
    return newItems;
}

function addBenefitItem(arr: Array<any>) {
    const newItems = arr;
    let key = newItems.length > 0 ? newItems.length : 1;
    const lastItem = newItems[key - 1];
    let newId;
    if (!lastItem?.id) {
        newId = 1;
    } else {
        newId = lastItem.id + 1;
    }
    return { id: newId, Fee: '', Quan: 1 };
}

function findBenefitItem(index: number, items: Array<any>) {
    const item = items.find((item) => item.id === index);
    return item;
}

function updateBenefitItem(itemId: number, items: Array<any>, data: any) {
    // const itemKey = items.findIndex((item) => item.id === index);
    // items[itemKey] = data;
    const newItems = items.map((item) => {
        if (item.id === itemId) {
            return data;
        }
        return item;
    });
    // console.log('ITEMS:', items);
    return newItems;
}

const BenefitCalculator: React.FC<BenefitCalculatorProps> = ({ handleClose, personHide, speciality = null }) => {
    const membership = useSelector((state: StoreType) => state.membership);
    const claimSpecialities = useSelector((state: StoreType) => state.claimSpeciality);
    const selectedUserId = useSelector((state: StoreType) => state.selectedUser);
    const appSettings = useSelector((state: StoreType) => state.settings);
    const lastUsedProviders = useSelector((state: StoreType) => state.lastProviders);
    const product = useSelector((state: StoreType) => state.product);

    const persons = allPersons(membership);
    const [selectedPerson, setSelectedPerson] = useState<string | undefined>(undefined);

    // nbsp before
    const [errorSpace, setErrorSpace] = useState<string>();
    const [failedAttempts, setFailedAttempts] = useState(0);

    let abortController = new AbortController();

    const [benefitItems, setBenefitItems] = useState<BenefitItem[]>([]);
    const [benefitService, setBenefitService] = useState('');
    const [providerAutocomplete, setProviderAutocomplete] = useState<ProviderType[]>([]);
    const [providerID, setProviderID] = useState<string>('');
    const [currentProvider, setCurrentProvider] = useState<ProviderType>();
    const [serviceItems, setServiceItems] = useState<ServiceItem[]>([]);
    const [isPharmacy, setIsPharmacy] = useState(false);

    const [totalAmount, setTotalAmount] = useState(parseFloat('0.0').toFixed(2));
    const [benefitAmount, setBenefitAmount] = useState(
        parseFloat('0.0').toFixed(2),
    );
    const [oopAmount, setOopAmount] = useState(parseFloat('0.0').toFixed(2));

    const [defaultProvider, setDefaultProvider] = useState('');
    const [feeRequired, setFeeRequired] = useState(true);

    const [disclaimerSelected, setDisclaimerSelected] = useState('');
    const [showDisclaimerSelected, setShowDisclaimerSelected] = useState(false);

    const [disclaimerResult, setDisclaimerResult] = useState('');
    const [showDisclaimerResult, setShowDisclaimerResult] = useState(false);
    const [standardDisclaimer, setStandardDisclaimer] = useState('');
    const [showStandardDisclaimer, setShowStandardDisclaimer] = useState(false);

    const [selectedLastProvider, setSelectedLastProvider] = useState('');
    const [usingLastProviders, setUsingLastProviders] = useState(false);

    const [disableQuoteButton, setDisableQuoteButton] = useState(true);

    useEffect(() => {
        if (speciality !== null) {
            console.log({ speciality });
            setBenefitService(speciality);
        }
    }, [speciality]);

    useEffect(() => {
        if (personHide !== true) {
            setSelectedPerson(mainPerson(membership)?.Person?.PersonId.toString());
        } else {
            setSelectedPerson(
                getSelectedUser(allPersons(membership), selectedUserId)?.Person
                    ?.PersonId,
            );
        }
    }, [membership]);

    useEffect(() => {
        if (currentProvider?.providerid) {
            // console.log('WE HAVE PROVIDER',currentProvider);
            providerItems(currentProvider.providerid, benefitService);
        }
    }, [currentProvider]);

    useEffect(() => {
        if (appSettings?.general?.general_disclaimer) {
            setStandardDisclaimer(appSettings?.general?.general_disclaimer);
        }
    }, [appSettings]);

    useEffect(() => {
        console.log('UPDATE benefitItems', benefitItems);
    }, [benefitItems]);

    function updatePrice() {
        if (benefitItems.length > 0) {
            let amount = parseFloat('0.00');
            setDisableQuoteButton(false);
            benefitItems.forEach((item) => {
                if (item?.Fee) {
                    if (
                        isPharmacy &&
                        parseFloat(item.Fee) <
                            parseFloat(appSettings.general.pbs_amount)
                    ) {
                        setDisableQuoteButton(true);
                    }
                    if (
                        !isPharmacy &&
                        feeRequired === true &&
                        parseFloat(item.Fee) === 0
                    ) {
                        setDisableQuoteButton(true);
                    }
                    if (
                        product?.ForceFeeMandatoryBenefit === true &&
                        parseFloat(item.Fee) === 0
                    ) {
                        setDisableQuoteButton(true);
                    }
                    amount = amount + parseFloat(item.Fee) * item?.Quan;
                } else {
                    if (isPharmacy) {
                        setDisableQuoteButton(true);
                    }
                    if (item?.FeeRequired === true) {
                        setDisableQuoteButton(true);
                    }
                    if (product?.ForceFeeMandatoryBenefit === true) {
                        setFeeRequired(true);
                        setDisableQuoteButton(true);
                    }
                }
            });
            setTotalAmount(amount.toFixed(2));

            if (feeRequired === true || product?.ForceFeeMandatoryBenefit) {
                if (amount === 0) {
                    setDisableQuoteButton(true);
                }
            } else {
                setDisableQuoteButton(false);
            }
        }
    }

    const autocompleteCallback = async (value: any, speciality: any): Promise<void> => {
        abortController.abort(); // Cancel the previous request
        abortController = new AbortController();

        const access_token = getAccessToken();

        const providerID = value;

        if (providerID === '') {
            setCurrentProvider(undefined);
            setProviderAutocomplete([]);
            return;
        }

        try {
            const response = await api
                .provider(CONFIG, access_token)
                .search(providerID, speciality, abortController.signal);
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                if (response.length > 0) {
                    if (
                        response.length === 1 &&
                        response[0].providerid === providerID
                    ) {
                        setCurrentProvider(response[0]);
                        setProviderAutocomplete([]);
                    } else {
                        setProviderAutocomplete(response);
                    }
                } else {
                    setProviderAutocomplete([]);
                }
            }
        } catch (err) {
            if (err instanceof Error) {
                if (err.name === 'AbortError') {
                    return; // Continuation logic has already been skipped, so return normally
                }
                console.log({ autocompleteProviderState });
                console.log('ERROR');
                console.log(err);
                setErrorSpace(err.message);
            }

            setFailedAttempts(1);
            setProviderAutocomplete([]);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
        }
    }

    const providerItemsCallback = async (providerID: any, speciality: any): Promise<void> => {
        const memberid = getMembershipId();
        const access_token = getAccessToken();

        try {
            if(!memberid) {
                throw new Error('No member ID found');
            }
            const response = await api
                .provider(CONFIG, access_token)
                .claimServices(memberid, providerID, speciality);
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                // console.log(response);
                // 054095BT - Med
                const serviceLineItems = convertToArray(response);
                if (serviceLineItems.length === 0) {
                    setErrorSpace(
                        'This service is not covered in your policy.',
                    );
                    setFailedAttempts(1);
                    setTimeout(() => {
                        setFailedAttempts(0);
                        setErrorSpace('');
                    }, 10000);
                } else {
                    setServiceItems(serviceLineItems);
                }
            }
        } catch (err) {
            console.log({ providerItemsState });
            console.log('ERROR');
            console.log(err);
            if (err instanceof Error) {
                setErrorSpace(err.message);
            }
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
        }
    }

    const calculateFee = (feeRequired: any, fee: any) => {
        if (feeRequired) {
            return fee !== '' ? fee : '0';
        } else {
            return fee !== '' ? fee : '9999999';
        }
    }

    const benefitQuoteCallback = async (providerID: any, personid: any, items: BenefitItem[], totalAmount: any, feeRequired: any) => {
        gtagEvent({
            screen: 'benefitcalc',
            action: 'quote',
            label: 'requested quote from benefit calculator',
            type: 'modal',
        });
        let totalQty = 0;
        let out: OutputItem[] = [];

        items.forEach((item) => {
            
            const temp = {
                PersonId: personid,
                ServiceDate: moment().format('YYYY-MM-DD'),
                ProviderCode: providerID,
                NumberOfServices: item.Quan,
                ServiceClassCode: item.ServiceClassCode,
                ServiceCode: item.ServiceCode,
                Fee: calculateFee(feeRequired, item.Fee),
                BodyPartCode: item.IsBodyPartRequired ? '0' : undefined,
            };

            out.push(temp);

            totalQty = totalQty + item.Quan;
        });

        const memberid = getMembershipId();
        const access_token = getAccessToken();
        try {
            if(!memberid) {
                throw new Error('No member ID found');
            }
            setBenefitAmount(parseFloat('0.0').toFixed(2));
            setOopAmount(parseFloat('0.0').toFixed(2));

            const response = await api
                .user(CONFIG, access_token)
                .benefits.makeBenefitQuote(memberid, out);
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                let tempBenefitAmount = parseFloat('0');
                response.forEach((item) => {
                    let tempAmount = parseFloat(item.Benefit);
                    tempBenefitAmount = tempBenefitAmount + tempAmount;
                });
                setBenefitAmount(tempBenefitAmount.toFixed(2));
                const tempDiff =
                    parseFloat(totalAmount) - tempBenefitAmount;
                if (tempDiff > 0) {
                    setOopAmount(tempDiff.toFixed(2));
                } else {
                    setOopAmount('N/A');
                }

                setShowDisclaimerResult(true);
                setShowStandardDisclaimer(true);
            }
        } catch (err) {
            if (err instanceof Error) {
                setErrorSpace(err.message);
            }
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
        }
    }

    const [autocompleteProviderState, autocompleteProvider] = useAsyncFn<any,any>(autocompleteCallback);

    const [providerItemsState, providerItems] = useAsyncFn<any, any>(providerItemsCallback);

    const [benefitQuoteState, benefitQuote] = useAsyncFn<any, any>(benefitQuoteCallback);

    return (
        <div className="row row-cols-1">
            <div
                className="col-6 pb-2"
                style={{
                    display: personHide === true ? 'none' : 'block',
                }}>
                <Form.Group
                    controlId={`person`}
                    className="text-label-gray mb-0">
                    <Form.Label>Person</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedPerson}
                        onChange={(e) => {
                            setSelectedPerson(e.target.value);
                            setCurrentProvider(undefined);
                            setProviderAutocomplete([]);
                            setProviderID('');
                            setBenefitService('');
                            setServiceItems([]);
                            setBenefitItems([]);
                            setTotalAmount(parseFloat('0.0').toFixed(2));
                            setBenefitAmount(parseFloat('0.0').toFixed(2));
                            setOopAmount(parseFloat('0.0').toFixed(2));
                            setSelectedLastProvider('');
                            setUsingLastProviders(false);
                        }}>
                        {persons
                            ? persons.map((person) => (
                                  <option
                                      value={person?.Person?.PersonId}
                                      key={
                                          person?.Person?.PersonId
                                      }>{`${person.Person.GivenName} ${person.Person.Surname}`}</option>
                              ))
                            : ''}
                    </Form.Control>
                </Form.Group>
            </div>
            <div
                className="col-6 pb-2"
                style={{
                    display: speciality !== null ? 'none' : 'block',
                }}>
                <Form.Group
                    controlId={`service`}
                    className="text-label-gray mb-0">
                    <Form.Label>Service Type</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={(e) => {
                            const tempBenefitService = claimSpecialities.find(
                                (spec) => spec.Description === e.target.value,
                            );
                            if (tempBenefitService?.SpecialityClassCode) {
                                setBenefitService(
                                    tempBenefitService.SpecialityClassCode,
                                );
                            } else {
                                setBenefitService('');
                            }
                            setCurrentProvider(undefined);
                            setProviderAutocomplete([]);
                            setProviderID('');
                            setServiceItems([]);
                            setBenefitItems([]);
                            setTotalAmount(parseFloat('0.0').toFixed(2));
                            setBenefitAmount(parseFloat('0.0').toFixed(2));
                            setOopAmount(parseFloat('0.0').toFixed(2));
                            setSelectedLastProvider('');
                            setUsingLastProviders(false);

                            if (
                                tempBenefitService?.DefaultProvider !== '' &&
                                tempBenefitService?.SpecialityClassCode
                            ) {
                                setDefaultProvider(
                                    tempBenefitService?.DefaultProvider,
                                );
                                setProviderID(
                                    tempBenefitService?.DefaultProvider,
                                );
                                autocompleteProvider(
                                    tempBenefitService?.DefaultProvider,
                                    tempBenefitService.SpecialityClassCode,
                                );

                                setCurrentProvider({
                                    providerid:
                                        tempBenefitService?.DefaultProvider,
                                });
                            } else {
                                setDefaultProvider('');
                            }

                            if (tempBenefitService?.FeeRequired !== false) {
                                setFeeRequired(true);
                            } else {
                                setFeeRequired(false);
                            }

                            if (e.target.value === 'Pharmacy') {
                                setIsPharmacy(true);
                            } else {
                                setIsPharmacy(false);
                            }

                            if (tempBenefitService?.DisclaimerSelected) {
                                tempBenefitService.DisclaimerSelected =
                                    tempBenefitService.DisclaimerSelected.replace(
                                        '|PBS_AMOUNT|',
                                        parseFloat(
                                            appSettings.general.pbs_amount,
                                        ).toFixed(2),
                                    );
                                setDisclaimerSelected(
                                    tempBenefitService.DisclaimerSelected,
                                );
                                setShowDisclaimerSelected(true);
                            } else {
                                setDisclaimerSelected('');
                                setShowDisclaimerSelected(false);
                            }
                            if (tempBenefitService?.DisclaimerResult) {
                                tempBenefitService.DisclaimerResult =
                                    tempBenefitService.DisclaimerResult.replace(
                                        '|PBS_AMOUNT|',
                                        parseFloat(
                                            appSettings.general.pbs_amount,
                                        ).toFixed(2),
                                    );
                                setDisclaimerResult(
                                    tempBenefitService.DisclaimerResult,
                                );
                                setShowDisclaimerResult(false);
                            } else {
                                setDisclaimerResult('');
                                setShowDisclaimerResult(false);
                            }
                        }}>
                        <option value="">- Please select -</option>
                        {typeof claimSpecialities?.map === 'function'
                            ? claimSpecialities.map((row, idx) => (
                                  <option key={idx} value={row.Description}>
                                      {row.Description}
                                  </option>
                              ))
                            : null}
                    </Form.Control>
                </Form.Group>
            </div>
            <div
                className="px-3 py-3"
                style={{
                    display: showDisclaimerSelected ? 'block' : 'none',
                    fontSize: '14px',
                }}>
                {disclaimerSelected}
            </div>
            <div
                className="col pb-2"
                style={{
                    display:
                        defaultProvider === '' && lastUsedProviders?.length > 0
                            ? 'block'
                            : 'none',
                }}>
                <Form.Group
                    controlId={`last-providers`}
                    className="text-label-gray mb-0">
                    <Form.Label>Last Used Providers</Form.Label>
                    <Form.Control
                        as="select"
                        disabled={benefitService === '' ? true : false}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setUsingLastProviders(false);
                                setCurrentProvider(undefined);
                                setProviderAutocomplete([]);
                                setProviderID('');
                                setServiceItems([]);
                                setBenefitItems([]);
                                setTotalAmount(parseFloat('0.0').toFixed(2));
                                setBenefitAmount(parseFloat('0.0').toFixed(2));
                                setOopAmount(parseFloat('0.0').toFixed(2));
                            } else {
                                setUsingLastProviders(true);
                            }
                            setCurrentProvider({ providerid: e.target.value });
                            setSelectedLastProvider(e.target.value);
                            setProviderID(e.target.value);
                        }}
                        value={selectedLastProvider}>
                        <option value=""></option>
                        {typeof lastUsedProviders?.map === 'function'
                            ? lastUsedProviders.map((item, idx) => {
                                  if (
                                      item.SpecialityClassCode ===
                                      benefitService
                                  ) {
                                      return (
                                          <option
                                              key={idx}
                                              value={item.providerid}>
                                              {item.providerid} - {item.title}{' '}
                                              {item.initials} {item.surname}
                                          </option>
                                      );
                                  }
                                  return <Fragment key={idx}></Fragment>;
                              })
                            : null}
                    </Form.Control>
                </Form.Group>
            </div>
            <div
                className="col pb-2"
                style={{
                    boxShadow: 'inset 0 -2px 0 0 #e5e4ea',
                    display:
                        defaultProvider === '' && !usingLastProviders
                            ? 'block'
                            : 'none',
                }}>
                <Form.Group
                    controlId={`provider-search`}
                    className="text-label-gray"
                    style={{ position: 'relative' }}>
                    <Form.Label>Provider Search</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={benefitService === '' ? true : false}
                        style={{
                            borderRadius: '23px',
                        }}
                        onChange={(e) => {
                            setProviderID(e.target.value);
                            autocompleteProvider(
                                e.target.value,
                                benefitService,
                            );
                        }}
                        value={providerID}
                    />
                    <div
                        className="provider-autocomplete"
                        style={{
                            display:
                                providerAutocomplete.length === 0
                                    ? 'none'
                                    : 'inline-block',
                        }}>
                        {typeof providerAutocomplete?.map === 'function' ? (
                            providerAutocomplete.map((provider, idx) => (
                                <div
                                    key={idx}
                                    onClick={() => {
                                        setProviderAutocomplete([]);
                                        setProviderID(provider.providerid);
                                        setCurrentProvider(provider);
                                    }}>
                                    <div className="top">
                                        {provider.providerid} - {provider.title}{' '}
                                        {provider.surname}
                                    </div>
                                    <div className="bottom">
                                        {provider.suburb}, {provider.state}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>
                                <Spinner
                                    animation="border"
                                    role="status"
                                    as="span"
                                    size="sm"
                                />
                            </div>
                        )}
                    </div>
                </Form.Group>
            </div>
            <div
                className="col pt-3 pb-3"
                style={{
                    backgroundColor: '#EFEFF5',
                    boxShadow: 'inset 0 -2px 0 0 #e5e4ea',
                }}>
                <div
                    className="font-weight-bold text-uppercase"
                    style={{
                        fontSize: '14px',
                    }}>
                    Benefit Quote
                </div>
                <div
                    className="d-flex font-weight-medium pt-2"
                    style={{
                        fontSize: '12px',
                    }}>
                    <div className="col-7">Item</div>
                    <div className="col-2 pl-2">Fee</div>
                    <div className="col-2">Qty</div>
                </div>
                <div>
                    {benefitItems.map((item, idx) => (
                        <div
                            className="d-flex font-weight-medium pt-0 pb-2 row"
                            key={idx}>
                            <div className="col-7 pr-1">
                                <Form.Group
                                    controlId={`benefit-item-${idx}`}
                                    className="text-label-gray mb-0">
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => {
                                            const currentLineData =
                                                findBenefitItem(
                                                    item.id,
                                                    benefitItems,
                                                );
                                            currentLineData.ServiceCode =
                                                e.target.value;
                                            const currentItem =
                                                serviceItems.find(
                                                    (serviceItem) =>
                                                        serviceItem.ServiceCode ===
                                                        e.target.value,
                                                );
                                            currentLineData.ServiceClassCode =
                                                currentItem?.ServiceClassCode;
                                            currentLineData.IsBodyPartRequired =
                                                currentItem?.IsBodyPartRequired;
                                            setBenefitItems(
                                                updateBenefitItem(
                                                    item.id,
                                                    benefitItems,
                                                    currentLineData,
                                                ),
                                            );

                                            updatePrice();

                                            if (
                                                currentItem?.DisclaimerSelected
                                            ) {
                                                currentItem.DisclaimerSelected =
                                                    currentItem.DisclaimerSelected.replace(
                                                        '|PBS_AMOUNT|',
                                                        parseFloat(
                                                            appSettings.general
                                                                .pbs_amount,
                                                        ).toFixed(2),
                                                    );
                                                setDisclaimerSelected(
                                                    currentItem.DisclaimerSelected,
                                                );
                                                setShowDisclaimerSelected(true);
                                            }
                                            if (currentItem?.DisclaimerResult) {
                                                currentItem.DisclaimerResult =
                                                    currentItem.DisclaimerResult.replace(
                                                        '|PBS_AMOUNT|',
                                                        parseFloat(
                                                            appSettings.general
                                                                .pbs_amount,
                                                        ).toFixed(2),
                                                    );
                                                setDisclaimerResult(
                                                    currentItem.DisclaimerResult,
                                                );
                                            }
                                            if (
                                                currentItem?.FeeRequired ===
                                                true
                                            ) {
                                                setFeeRequired(true);
                                            }
                                        }}>
                                        <option>- Please select -</option>
                                        {serviceItems.length > 0
                                            ? serviceItems.map(
                                                  (item2, idx2) => (
                                                      <option
                                                          key={idx2}
                                                          value={
                                                              item2.ServiceCode
                                                          }>
                                                          {item2.ServiceCode} -{' '}
                                                          {item2.Description}
                                                      </option>
                                                  ),
                                              )
                                            : null}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-2 pl-1 pr-1">
                                <Form.Group
                                    controlId={`benefit-fee-${idx}`}
                                    className="text-label-gray mb-0">
                                    <Form.Control
                                        type="text"
                                        name="fee"
                                        placeholder="0.00"
                                        value={item?.Fee}
                                        onChange={(e) => {
                                            const testRegex =
                                                /^[0-9]*\.?[0-9]{0,3}$/g;
                                            const valid = testRegex.test(
                                                e.target.value,
                                            );

                                            if (valid) {
                                                const price = reversePriceMask(
                                                    e.target.value,
                                                    '0.00',
                                                );
                                                let currentLineData =
                                                    findBenefitItem(
                                                        item.id,
                                                        benefitItems,
                                                    );
                                                currentLineData.Fee = price;
                                                setBenefitItems(
                                                    updateBenefitItem(
                                                        item.id,
                                                        benefitItems,
                                                        currentLineData,
                                                    ),
                                                );

                                                updatePrice();
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-2 pl-1 pr-1">
                                <Form.Group
                                    controlId={`benefit-qty-${idx}`}
                                    className="text-label-gray mb-0">
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        value={item.Quan}
                                        onChange={(e) => {
                                            let currentLineData2 =
                                                findBenefitItem(
                                                    item.id,
                                                    benefitItems,
                                                );
                                            currentLineData2.Quan = parseInt(e.target.value);
                                            setBenefitItems(
                                                updateBenefitItem(
                                                    item.id,
                                                    benefitItems,
                                                    currentLineData2,
                                                ),
                                            );

                                            updatePrice();
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div
                                className={`${
                                    idx === 0 ? 'd-none' : ''
                                } col-1 align-self-center`}>
                                <AiFillCloseCircle
                                    style={{
                                        verticalAlign: 'middle',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setBenefitItems(
                                            removeBenefitItem(
                                                item.id,
                                                benefitItems,
                                            ),
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    <div>
                        <Button
                            variant="link"
                            className="text-primary-dark-1"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'benefitcalc',
                                    action: 'additem',
                                    label: 'added item to quote on benefit calculator',
                                    type: 'modal',
                                });
                                setBenefitItems((benefitItems) => [
                                    ...benefitItems,
                                    addBenefitItem(benefitItems),
                                ]);
                            }}
                            style={{
                                padding: '8px',
                            }}
                            disabled={
                                !currentProvider?.providerid ||
                                serviceItems.length === 0
                                    ? true
                                    : false
                            }>
                            <FaPlusCircle style={{ verticalAlign: 'middle' }} />{' '}
                            ADD ITEM
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col pt-3 pb-3">
                <div className="d-flex">
                    <div className="col-4">
                        <div
                            className="text-uppercase text-light-gray font-weight-medium"
                            style={{
                                fontSize: '11px',
                            }}>
                            Total
                        </div>
                        <div
                            className="text-uppercase text-black font-weight-bold"
                            style={{
                                fontSize: '22px',
                            }}>
                            {totalAmount === '0.00' ? 'N/A' : `$${totalAmount}`}
                        </div>
                    </div>
                    <div className="col-4">
                        <div
                            className="text-uppercase text-light-gray font-weight-medium"
                            style={{
                                fontSize: '11px',
                            }}>
                            Benefits
                        </div>
                        <div
                            className="text-uppercase font-weight-bold"
                            style={{
                                fontSize: '22px',
                                color: '#429F33',
                            }}>
                            ${benefitAmount}
                        </div>
                    </div>
                    <div className="col-4">
                        <div
                            className="text-uppercase text-light-gray font-weight-medium"
                            style={{
                                fontSize: '11px',
                            }}>
                            Out of pocket
                        </div>
                        <div
                            className="text-uppercase text-black font-weight-bold"
                            style={{
                                fontSize: '22px',
                            }}>
                            {oopAmount !== 'N/A' ? `$${oopAmount}` : oopAmount}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col pt-3 pb-3">
                <Button
                    variant="secondary"
                    onClick={() => {
                        benefitQuote(
                            providerID,
                            selectedPerson,
                            benefitItems,
                            totalAmount,
                            feeRequired,
                        );
                    }}
                    disabled={
                        benefitQuoteState.loading ||
                        benefitItems.length === 0 ||
                        disableQuoteButton
                    }
                    className="mr-3">
                    {benefitQuoteState.loading ? (
                        <>
                            <Spinner
                                animation="border"
                                role="status"
                                as="span"
                                size="sm"
                            />
                            Do Quote
                        </>
                    ) : (
                        'Do quote'
                    )}
                </Button>
                <Cancel
                    style={{
                        color: '#9998A8',
                    }}
                    onClick={() => {
                        if (handleClose) {
                            handleClose();
                        }
                        setBenefitService('');
                        setCurrentProvider(undefined);
                        setProviderAutocomplete([]);
                        setProviderID('');
                        setServiceItems([]);
                        setBenefitItems([]);
                        setTotalAmount(parseFloat('0.0').toFixed(2));
                        setBenefitAmount(parseFloat('0.0').toFixed(2));
                        setOopAmount(parseFloat('0.0').toFixed(2));
                    }}
                />
            </div>
            <div
                className="px-3 py-3"
                style={{
                    display:
                        showDisclaimerResult && disclaimerResult !== ''
                            ? 'block'
                            : 'none',
                    fontSize: '14px',
                }}>
                {disclaimerResult}
            </div>
            <div
                className="px-3 py-3"
                style={{
                    display:
                        showStandardDisclaimer && standardDisclaimer !== ''
                            ? 'block'
                            : 'none',
                    fontSize: '14px',
                }}>
                {standardDisclaimer}
            </div>
            <span
                className={'text-danger text-center mb-1 mt-4 font-feature'}
                style={{
                    display: failedAttempts ? 'block' : 'none',
                }}>
                <Fade collapse top when={failedAttempts > 0}>
                    {errorSpace}
                </Fade>
            </span>
        </div>
    );
};

export default BenefitCalculator;
