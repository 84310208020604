import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/all';
import { fastClaimActions } from 'store';
import gtagEvent from 'libs/gtagEvent';
import ClaimDetails from 'components/sections/claim/ClaimDetails';
import PaymentDetails from 'components/sections/claim/PaymentDetails';
import FinaliseClaim from 'components/sections/claim/FinaliseClaim';
import { StoreType } from 'store/types';

export const FasterClaimPage = () => {
    const fastClaimStore = useSelector((state: StoreType) => state.fastClaim);
    const dispatch = useDispatch();
    const currentAccordionKey = fastClaimStore.currentStep;

    useEffect(() => {
        dispatch(fastClaimActions.general.reset());
    }, []);

    return (
        <main className="p-4 claim-new-page">
            <div className="row">
                <div className="col-12">
                    <Card className="">
                        <div
                            className="p-4 text-primary font-weight-medium text-uppercase"
                            style={{ fontSize: '21px' }}>
                            New Claim
                        </div>

                        <Accordion activeKey={currentAccordionKey}>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="0"
                                    onClick={() => {
                                        if (parseInt(currentAccordionKey) > 0) {
                                            gtagEvent({
                                                screen: 'fastclaim',
                                                action: 'one_accordion',
                                                label: 'User navigated to the first step',
                                            });
                                            dispatch(
                                                fastClaimActions.general.setStep(
                                                    '0',
                                                ),
                                            );
                                        }
                                    }}>
                                    <span className="num">1</span> Claim Details{' '}
                                    <span className="chevron">
                                        {currentAccordionKey === '0' ? (
                                            <FaChevronDown />
                                        ) : (
                                            <FaChevronRight />
                                        )}
                                    </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="px-3 py-0">
                                        <ClaimDetails />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="1"
                                    onClick={() => {
                                        if (parseInt(currentAccordionKey) > 1) {
                                            gtagEvent({
                                                screen: 'fastclaim',
                                                action: 'two_accordion',
                                                label: 'User navigated to the second step',
                                            });
                                            dispatch(
                                                fastClaimActions.general.setStep(
                                                    '1',
                                                ),
                                            );
                                        }
                                    }}>
                                    <span className="num">2</span> Payment
                                    Details{' '}
                                    <span className="chevron">
                                        {currentAccordionKey === '1' ? (
                                            <FaChevronDown />
                                        ) : (
                                            <FaChevronRight />
                                        )}
                                    </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body className="px-3 py-0">
                                        <PaymentDetails />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="2"
                                    onClick={() => {
                                        if (parseInt(currentAccordionKey) > 2) {
                                            gtagEvent({
                                                screen: 'fastclaim',
                                                action: 'three_accordion',
                                                label: 'User navigated to the third step',
                                            });
                                            dispatch(
                                                fastClaimActions.general.setStep(
                                                    '2',
                                                ),
                                            );
                                        }
                                    }}>
                                    <span className="num">3</span> Finalise
                                    Claim{' '}
                                    <span className="chevron">
                                        {currentAccordionKey === '2' ? (
                                            <FaChevronDown />
                                        ) : (
                                            <FaChevronRight />
                                        )}
                                    </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body className="px-3 py-0">
                                        <FinaliseClaim />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Card>
                </div>
            </div>
        </main>
    );
};
